import { navigate } from 'gatsby';
import { useEffect } from 'react';

const IndexPage = () => {
    useEffect(() => {
        navigate('/app/dashboard', { replace: true });
    }, []);

    return null;
};
export default IndexPage;
